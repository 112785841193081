/* src/components/fonts.css */
@font-face {
  font-family: 'Garet';
  src: url('./fonts/Garet-Book.ttf') format ('truetype'),
  url('./fonts/Garet-Heavy.ttf') format ('truetype');
       
  font-weight: normal;
  font-style: normal;
}

/* Apply the font to body or specific elements */



body {
  background-color: #F8F1E8 !important;
  color: #000 !important;
  font-family: "Scope One", serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}
h1,h2,h3,h4,h5,h6{
  color: #000;
}

.logo-size img {
  width: 100%;
}


.title_head {
  text-align: center;
}

.learn-style_heading h1 {
  font-size: 42px;
  text-align: center;
  font-weight: 600;
}

.learn-style p {
  font-size: 24px;
  text-align: center;
}

.cus_menu a.nav-link {
  padding: 0px 20px !important;
  color: #000 !important;
  font-size: 21.4px;
}

.right_head h3 {
  text-align: right;
  font-weight: 600;
  font-size: 42.3px;
}

.float-end.process img {
  width: 60%;
}

.explo_cnt p {
  text-align: center;
  font-size: 24.8px;
}

.cnt p {
  font-size: 24.8px;
  text-align: center;
  padding: 48px 0px;
}

.unlock h4 {
  font-size: 42.3px;
  font-weight: 600;
}

.unl_p p {
  font-size: 24.8px;
  text-align: center;
}
.unlock h4 {
  font-size: 42.3px;
  font-weight: 500;
  text-align: center;
  line-height: 55px;
  padding-top: 15px;
  font-family: 'Garet', sans-serif;
  letter-spacing: 3px;
}

.good h5 {
  font-size: 68.9px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  -webkit-text-stroke: 3px #FF9E5E;
  color: #ffffff;
  text-transform: uppercase;
}

.join h5 {
  font-size: 42.3px;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-style: normal;
  line-height: 85px;
  padding-right: 8px;
}

.join_text {
  font-size: 24.8px;
  text-align: center;
}

.custm_txt h5 {
  font-size: 49.3px;
  text-align: center;
  font-weight: 500;
  line-height: 60px;
  font-family: 'Garet', sans-serif;
  letter-spacing: 3px;
}

.cus_prea p {
  font-size: 30px;
  text-align: center;
}

.right_imgew img {
  width: 100%;
  float: right;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container img {
  display: block;
  width: 28%;
  height: auto;
}

.overlay-text {
  position: absolute;
  top: 70%;
  left: 58%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.parent_cnt p {
  font-size: 24px;
  text-align: center;
}

.ready {
  text-align: center;
}

.ready h6 {
  text-align: center;
  font-size: 42.3px;
  font-family: 'Garet', sans-serif;
}

.ready p {
  font-size: 24px;
}

.about_head h1 {
  font-size: 42px;
  font-family: 'Garet', sans-serif;
  text-align: center;
  letter-spacing: 3px;
}

.about_cnt p {
  font-size: 21.3px;
  text-align: center;
  padding-top: 8px;
}

.about_hd h1 {
  text-align: center;
  font-size: 42.3px;
  font-family: 'Garet', sans-serif;
}

.about_hd p {
  font-size: 24.8px;
  text-align: center;
}

.about_hd {
  padding: 100px 0px 0px 0px;
}

.about_last h3 {
  text-align: center;
  font-size: 42.3px;
  font-family: 'Garet', sans-serif;
  padding-bottom: 11px;
}

.benifits {
  text-align: center;
}

.benifits h2 {
  font-size: 16px;
  font-family: 'Garet', sans-serif;
}

.benifits ul li {
  font-size: 16px;
  line-height: 53px;
}

.benifits ul li a {
  color: #000;
  font-weight: 600;
}

.about_last {
  padding: 122px 0px 0px 0px;
}

.copyright {
  text-align: right;
  font-size: 14px;
  font-family: 'Garet', sans-serif;
}

.oname span{
  text-align: right;
  font-size: 14px;
  font-family: 'Garet', sans-serif;
}

section.sticy-header.logo-size nav {
  background-color: #f8f1e8 !important;
}